import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";

function Privacy(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/logo.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="aus"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">Privacy Policy</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-sm-12 align-justify">
                            <h2 className="fw-bold">Privacy Policy for Aus Dream Built</h2>

                            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in  Aus Dream Built . This policy is not applicable to any information collected offline or via channels other than this website.</p>
                            
                            <h5 className="fw-bold pt-3">Consent</h5>

                            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                         
                            <h5 className="fw-bold pt-3">Third Party Privacy Policies</h5>

                            <p>Aus Dream Built Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
                            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
                           
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Privacy