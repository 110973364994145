import _ from "lodash";
import { useState } from "react";

function ChangeStatus(props) {
    const [status, setStatus] = useState("All")
    const [title, setTitle] = useState(props.allTitle ?? "All")

    const changeStatus = (status) => {
        setStatus(status === "" ? "All" : status)
        if(props.allTitle && status === "") setTitle("Status")
        else setTitle(status === "" ? "All" : status)
        props.action(status)
    }
    return (<>
        <style jsx="true">{`
            .w-200px{
                width: 200px
            }
            .dropdown-toggle::after{
                float: right;
                margin-top: 7px;
            }
        `}</style>
        <div className="dropdown d-inline-block align-top ms-2">
            <button className="btn btn-sm btn-light border align-top dropdown-toggle w-200px text-start" data-bs-toggle="dropdown" title={`${_.startCase(title === 'cancel' ? 'Cancelled' : title)} ${props.module}`}>{_.startCase(title === 'cancel' ? 'Cancelled' : title)} {props.module}</button>
            <ul className="dropdown-menu shadow border-0 p-2">
                <li>
                    <label className={`dropdown-item rounded ${status === "All" && "active"}`} onClick={() => changeStatus('')}>
                        All
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${status === "pending" && "active"}`} onClick={() => changeStatus('pending')}>
                        Pending
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${status === "in_progress" && "active"}`} onClick={() => changeStatus('in_progress')}>
                        In Progress
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${status === "hold" && "active"}`} onClick={() => changeStatus('hold')}>
                        Hold
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${status === "completed" && "active"}`} onClick={() => changeStatus('completed')}>
                        Completed
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${status === "cancel" && "active"}`} onClick={() => changeStatus('cancel')}>
                        Cancelled
                    </label>
                </li>
            </ul>
        </div>
    </>)
}

export default ChangeStatus;