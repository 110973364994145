import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { DeleteButton, EditButton, ViewButtonModal } from '../../components/ButtonHelper';
import { GET_PROJECT_BY_STATUS, INVENTORY_CHANGE_STATUS, INVENTORY_EDIT, INVENTORY_LIST, INVENTORY_SPEND_CREATE, INVENTORY_SPEND_LIST } from '../../components/ApiRoutes';
import { ButtonSpinner, ConfirmationModal, fetchData, getPermission, initialFormState, ModalSection, validateForm } from '../../components/Helper';
import Context from '../../components/Context';

function SubItemList() {
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [routeDetails, setRouteDetails] = useState()
    const [inventoryId, setInventoryId] = useState()
    const [deleteItemId, setDeleteItemId] = useState()
    const [activeProject, setActiveProject] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const itemId = useParams().item_id
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "inventory")), [context])

    const [dt] = useState({
        dt_url: `${INVENTORY_LIST}?item_id=${itemId}`,
        dt_name: 'inventory_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "name", name: 'name', title: "Sub Item Name" },
            { data: "stock", name: 'stock', title: "In Stock" },
            { data: "image", name: 'image', title: "Image" },
            { data: "updated_at", name: 'updated_at', title: "Update At" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.image &&
                            <div className="table-imgbox table-img60" role="button" data-bs-target="#zoomImageModal" data-bs-toggle="modal" title="Zoom Image" data-previewimg={process.env.REACT_APP_ASSET_URL + records.image}>
                                <img src={process.env.REACT_APP_ASSET_URL + records.image} alt="ausdreambuilt" />
                            </div>
                        }
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "inventory")
                    let permission2 = getPermission(context, "inventory__spent")

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {permission2.view && <ViewButtonModal modal_id="#inventorySpend" action={() => inventorySpendList(records.id)} />}
                            {permission.edit && <EditButton action={() => navigate(`/erp/inventory/${itemId}/edit/${records.id}`)} />}
                            {permission.delete && <DeleteButton modal_id="#itemDeleteModal" action={() => setDeleteItemId(records.id)} />}
                        </div>
                    </>)
                }
            }
        ],
    })

    const handleChangeStatus = (status, id) => {
        fetchData(INVENTORY_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        })
    }

    const inventorySpendList = (id) => {
        let dt_inventory_spend_list = {
            dt_url: `${INVENTORY_SPEND_LIST}/${id}`,
            dt_name: 'inventory_spend_list',
            dt_order: [[0, 'desc']],
            dt_column: [
                { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
                { data: "project_name", name: 'project_name', title: "Project Name" },
                { data: "stock", name: 'stock', title: "Stock Spent" },
                { data: "created_at", name: 'created_at', title: "Date" }
            ],
        };

        setInventoryId(id)

        reloadUrlDataTable(dt_inventory_spend_list, `${INVENTORY_SPEND_LIST}/${id}`)
    }

    const saveSpent = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('form-add-spent'))
            formdata.append('inventory_id', inventoryId)

            fetchData(INVENTORY_SPEND_CREATE, 'POST', formdata, true, true, (res) => {
                if (res.status) {
                    inventorySpendList(inventoryId)
                }
                setTinyloader(false)
            })
        }
    }

    useEffect(() => {
        document.getElementById("inventorySpend").addEventListener('hide.bs.modal', function (e) {
            initialFormState('form-add-spent', [])
            loadDataTable(dt)
        })
    }, [dt])

    useEffect(() => {
        if (itemId) {
            fetchData(`${INVENTORY_EDIT}/${itemId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setRouteDetails(res.data)
                }
            }, false, false, false)
        }

        fetchData(GET_PROJECT_BY_STATUS+ '?status=in_progress', 'get', '', true, false, (res) => {
            if (res.status) {
                setActiveProject(res.data)
            }
        }, false, false, false)

        reloadUrlDataTable(dt, INVENTORY_LIST)
    }, [dt, itemId])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            {routeDetails ? <h6 className="m-0 heading-lines fw-medium pb-2">
                                <Link to={`/erp/inventory`} className="text-decoration-none" title="Inventory">Inventory </Link>
                                <i className="bi bi-chevron-double-right fs-14px"></i> {routeDetails && routeDetails.name}
                            </h6> : <h6 className="m-0 heading-lines fw-medium pb-2">Inventory</h6>}
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/inventory/${itemId}/add`} className="btn btn-blue btn-sm px-3 me-2" title="Add Sub Items"><i className="bi bi-plus-circle-fill me-1"></i> Add Sub Items</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="inventory_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ModalSection title="Inventory Spend List" modal_id="inventorySpend" size="lg">
                {context && context.permissions && context.permissions.inventory__spent && context.permissions.inventory__spent.includes('add') &&
                    <form action="#" method="post" className="needs-validation pb-4" id="form-add-spent" noValidate>
                        {activeProject &&
                            <div className="mb-4 row mx-0">
                                <div className="col-sm-12 col-lg-12 col-xxl-12 px-0 pb-3 text-center"><h6>Add Spent Stock</h6></div>
                                <div className="col-sm-12 col-lg-5 col-xxl-5 ps-0">
                                    <select className="form-select" id="project_id" name="project_id" required={true}>
                                        <option className="text-muted" value="">Select A Project*</option>
                                        {activeProject.map((project, index) => <option key={index} value={project.id}>{project.name}</option>)}
                                    </select>
                                    <div className="invalid-feedback">The project field is required.</div>
                                </div>
                                <div className="col-sm-12 col-lg-5 col-xxl-5">
                                    <input type="number" className="form-control" id="stock" name="stock" required={true} placeholder="Stock*" />
                                    <div className="invalid-feedback">The stock field is required.</div>
                                </div>
                                <div className="col-sm-12 col-lg-5 col-xxl-2 pe-0">
                                    <button type="button" className="btn btn-blue w-120px px-4 me-2" title="Save" onClick={saveSpent}>
                                        <ButtonSpinner load={tinyloader} btnName="Save" />
                                    </button>
                                </div>
                            </div>
                        }
                    </form>
                }

                <div className="table-responsive p-1">
                    <Datatables dt_name="inventory_spend_list" dataPageLength={10}  />
                </div>
            </ModalSection>

            <ConfirmationModal modal_id="itemDeleteModal" title="Delete Item" action={() => handleChangeStatus('delete', deleteItemId)}>
                <p className="">Are you sure want to delete this item?</p>
            </ConfirmationModal>
        </>
    )
}

export default SubItemList