import { useContext, useEffect, useState } from "react";
import {  ACCOUNT_UPDATE } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm} from "../../components/Helper";
import Context from "../../components/Context";
import { GET_PROFILE } from "../../components/ApiRoutes";

function Editprofile() {
    const [context, setContext]= useContext(Context);
    const userId = context && context.auth && context.auth.id;
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()

    useEffect(() => {
        if (userId) {
            fetchData(`${GET_PROFILE}/${userId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                }
            })
        }
    }, [userId])

    const updateAccount = (e) => {
        
        if (validateForm(e)) {
           setTinyloader(true)
            let api_url = ACCOUNT_UPDATE;
            let formdata = new FormData(document.getElementById('accountupdatingForm'))
           
            if (userId) {
                api_url = ACCOUNT_UPDATE
                formdata.append('user_id', userId);
                formdata.append('profile_update', true);
                  
            } 
            
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: {...context.auth , profile_image: res.data.profile_image}
                    }))
                }
            }) 
          
        }
    }
    return (
        
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">My Profile</h6>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="accountupdatingForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <InputField name="email" value={data && data.email} disabled={userId} required />
                                    <InputField type="number" name="phone" value={data && data.phone} />
                                    <InputField type="file" name="profile_image"  />
                                    <SubmitButton load={tinyloader} title={userId ? "Update" : "Save" } action={updateAccount} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Editprofile;