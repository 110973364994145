import { useContext, useEffect, useState } from "react";
import _ from 'lodash';
import ChartComponant from '../../components/ChartComponant';
import { fetchData } from '../../components/Helper';
import { DASHBOARD_STATS } from '../../components/ApiRoutes';
import Context from '../../components/Context';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [context] = useContext(Context)
    const navigate = useNavigate()

    const [dashboardData, setDashboardData] = useState()
    const [bgColor] = useState([
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'
    ])

    const [dateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange

    const adminRecord = {
        labels: dashboardData && dashboardData.adminRecord ? Object.keys(dashboardData.adminRecord) : '',
        datasets: [{
            label: 'Admins',
            data: dashboardData && dashboardData.adminRecord ? Object.values(dashboardData.adminRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const projectManagerRecord = {
        labels: dashboardData && dashboardData.projectManagerRecord ? Object.keys(dashboardData.projectManagerRecord) : '',
        datasets: [{
            label: 'Project Managers',
            data: dashboardData && dashboardData.projectManagerRecord ? Object.values(dashboardData.projectManagerRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const clientRecord = {
        labels: dashboardData && dashboardData.clientRecord ? Object.keys(dashboardData.clientRecord) : '',
        datasets: [{
            label: 'Clients',
            data: dashboardData && dashboardData.clientRecord ? Object.values(dashboardData.clientRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const inventoryRecord = {
        labels: dashboardData && dashboardData.inventoryRecord ? Object.keys(dashboardData.inventoryRecord) : '',
        datasets: [{
            label: 'Inventory Summary',
            data: dashboardData && dashboardData.inventoryRecord ? Object.values(dashboardData.inventoryRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const projectRecord = {
        labels: dashboardData && dashboardData.projectRecord ? Object.keys(dashboardData.projectRecord) : '',
        datasets: [{
            label: 'Projects',
            data: dashboardData && dashboardData.projectRecord ? Object.values(dashboardData.projectRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const scales = {

        x: {
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
                crossAlign: 'far',
            }
        }
    }

    const plugins = {
        legend: {
            display: false,
            position: 'top',
        },
        label: {
            display: false
        },
    }

    useEffect(() => {
        fetchData(`${DASHBOARD_STATS}?startDate=${startDate}&endDate=${endDate}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setDashboardData(res.data)
            }
        })
    }, [startDate, endDate]);

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-4">
                        <h6 className="m-0 heading-lines fw-medium pb-2">Dashboard</h6>
                    </div>
                    {/* <div className="col col-auto mb-2">
                        <div className="d-flex mb-3">
                            <DateRangeFilter
                                daterange="All"

                                className="form-control"
                                maxDate={new Date()}
                                opens="left"
                                name='d_daterange'
                                reload={refreshData}
                                disableUpdateInput={false}
                                getDateRange={getDateRange}
                            />
                        </div>
                    </div> */}
                </div>

                {dashboardData && context && context.permissions &&
                    <div className="row g-3 g-xl-4 row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
                        {context.permissions.user_management__admin && context.permissions.user_management__admin.includes('view')  && <div className="col adb-mt-19">
                            <div className="card border-0 shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/erp/user-management/admin/accounts`)}>
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Admins</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalAdmin : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-110px">
                                            <ChartComponant key="1" id="admin_chart" plugins={plugins} label={adminRecord.labels} scale={scales} data={adminRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('view') && <div className="col adb-mt-19">
                            <div className="card border-0 shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/erp/user-management/project-manager/accounts`)}>
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Project Managers</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalProjectManager : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-110px">
                                            <ChartComponant key="2" id="project_manager_chart" plugins={plugins} label={projectManagerRecord.labels} scale={scales} data={projectManagerRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {context.permissions.user_management__client && context.permissions.user_management__client.includes('view') && <div className="col adb-mt-19">
                            <div className="card border-0 shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/erp/user-management/client/accounts`)}>
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Clients</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalClient : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-110px">
                                            <ChartComponant key="3" id="client_chart" plugins={plugins} label={clientRecord.labels} scale={scales} data={clientRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {context.permissions.project_management__details && context.permissions.project_management__details.includes('view') && <div className="col adb-mt-19">
                            <div className="card border-0 shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/erp/project`)}>
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Projects</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalProject : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-110px">
                                            <ChartComponant key="4" id="projects_chart" plugins={plugins} label={projectRecord.labels} scale={scales} data={projectRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {context.permissions.inventory && context.permissions.inventory.includes('view') && <div className="col adb-mt-19">
                            <div className="card border-0 shadow-sm rounded-10 h-100" role="button" onClick={() => navigate(`/erp/inventory`)}>
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Inventory Summary</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0"><span className="fs-18px text-muted">In Stock </span>{dashboardData ? dashboardData.totalStock : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-110px">
                                            <ChartComponant key="5" id="inventory_chart" plugins={plugins} label={inventoryRecord.labels} scale={scales} data={inventoryRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                }
            </div>
        </section >
    )
}

export default Dashboard