import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { ACCOUNT_CREATE, ACCOUNT_EDIT, ACCOUNT_UPDATE } from "../../components/ApiRoutes";
import { InputField, PasswordField, SubmitButton } from "../../components/FormHelper";
import { fetchData, intlTel_phone, validateForm, validatePhone } from "../../components/Helper";
import 'intl-tel-input/build/css/intlTelInput.css';

function AccountCreate() {
    const navigate = useNavigate();
    const accountType = useParams().account_type
    const userId = useParams().id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        if (userId) {
            fetchData(`${ACCOUNT_EDIT}/${userId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                    setTimeout(() => setIntlTel(intlTel_phone('#phone', '', 'us', true)), 0);
                }
            })
        } else {
            setIntlTel(intlTel_phone('#phone', '', 'us', true)) 
        }
    }, [userId])

    const createAccount = (e) => {
        let phone = intlTel.getNumber()
        if (validateForm(e) && (!phone || validatePhone(intlTel, '#phone'))) {
            setTinyloader(true)
            let api_url = ACCOUNT_CREATE;
            let formdata = new FormData(document.getElementById('accountCreateForm'))
            formdata.append('role', accountType)
            formdata.append('phone',  phone)
            if (userId) {
                api_url = ACCOUNT_UPDATE
                formdata.append('user_id', userId)
            }
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/user-management/${accountType}/accounts`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">{userId ? "Update" : "Add New"} {_.startCase(accountType)}</h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/user-management/${accountType}/accounts`} className="btn btn-blue btn-sm me-2" title={`${_.startCase(accountType)} List`}><i className="bi bi-list-task"></i> {_.startCase(accountType)} List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="accountCreateForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <InputField name="email" value={data && data.email} disabled={userId} required />
                                    <PasswordField name="password" suggestion={userId ? "Leave password field blank, if you do not want to change." : false} required={!userId} />
                                    <InputField name="phone" value={data && data.phone ? "+" + data.phone : null} />
                                    <SubmitButton load={tinyloader} title={userId ? "Update" : "Save"} action={createAccount} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccountCreate