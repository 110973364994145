import _ from "lodash";
import { useEffect, useState } from "react";
import { PROJECT_TASK_BY_CATEGORY } from "../../../components/ApiRoutes";
import { fetchData } from "../../../components/Helper";

function FilterTaskList(props) {
    const [taskType, setTaskType] = useState("All")
    const [taskId, setTaskId] = useState("")
    const [tasks, setTasks] = useState([])
    const [title, setTitle] = useState("All")

    useEffect(() => {
        if (props.projectStep) {
            fetchData(`${PROJECT_TASK_BY_CATEGORY}/${props.projectId}/${props.projectStep}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setTasks(res.data)
                }
            }, false, false, false)
        }
    }, [props.projectStep, props.projectId, props.reload])

    const filter = (e, task = false) => {
        setTitle(task ? e.task_name : (e === "" ? "All" : e))
        setTaskId(task ? e.id : e)
        setTaskType(task ? e.id : e)
        props.action(task ? `task_id=${e.id}` : `type=${e}`)
    }

    return (<>
        <style jsx="true">{`
            .w-200px{
                width: 200px
            }
            .dropdown-toggle::after{
                float: right;
                margin-top: 7px;
            }
            .task-title{
                line-height: normal;
                font-size: 15px;
            }
        `}</style>
        <div className="dropdown d-inline-block align-top ms-2">
            <button className="pb-0 btn pt-1 btn-sm btn-light border align-top dropdown-toggle w-200px text-start text-truncate" data-bs-toggle="dropdown" title={`${_.startCase(title)} ${props.module}`}>
                <span className="d-inline-block p-0 mmw-170px text-truncate task-title"> {_.startCase(title)} {props.module}</span>
            </button>
            <ul className="dropdown-menu shadow border-0 w-200px p-2" style={{maxHeight: "400px", overflow: "auto"}}>
                <span className='small text-info'>Type Filter</span>
                <li>
                    <label className={`dropdown-item rounded ${taskType === "" && "active"}`} onClick={() => filter('')}>
                        All
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${taskType === "task" && "active"}`} onClick={() => filter('task')}>
                        Tasks
                    </label>
                </li>
                <li>
                    <label className={`dropdown-item rounded ${taskType === "sub_task" && "active"}`} onClick={() => filter('sub_task')}>
                        Sub Tasks
                    </label>
                </li>

                <span className='small text-info'>Sub Task Filter</span>
                {tasks.map((task, index) => {
                    return (
                        <li key={index}>
                            <label className={`dropdown-item rounded text-truncate ${taskId === task.id && "active"}`} onClick={() => filter(task, true)}>
                                {task.task_name}
                            </label>
                        </li>
                    )
                })}
            </ul>
        </div>
    </>)
}

export default FilterTaskList;