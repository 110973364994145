import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";

function Term(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/logo.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="aus"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">Terms & Conditions</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row g-4">
                <div className="col-sm-12 align-justify">
                            <h2 className="fw-bold">What are Terms and Conditions Agreements?</h2>

                            <p>Your Terms and Conditions agreement will be uniquely yours. While some clauses are standard and commonly seen in pretty much every Terms and Conditions agreement, it's up to you to set the rules and guidelines that the user must agree to.</p>
                            <p>Terms and Conditions agreements are also known as Terms of Service or Terms of Use agreements. These terms are interchangeable, practically speaking.</p>
                            
                            <h5 className="fw-bold pt-3">Is a Terms and Conditions Agreement Required?</h5>

                            <p>A Terms and Conditions is not required and it's not mandatory by law.</p>
                           
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Term