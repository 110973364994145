import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { INVENTORY_CREATE, INVENTORY_EDIT, INVENTORY_UPDATE } from "../../components/ApiRoutes";
import { FileField, InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function SubItemAdd() {
    const navigate = useNavigate();
    const location = useLocation();
    const itemId = useParams().item_id
    const subItemId = useParams().sub_item_id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()
    const [routeDetails, setRouteDetails] = useState()

    useEffect(() => {
        if (itemId) {
            fetchData(`${INVENTORY_EDIT}/${itemId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setRouteDetails({ item: res.data })
                }
            })
        }

        if (subItemId) {
            fetchData(`${INVENTORY_EDIT}/${subItemId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)

                    setRouteDetails(prevState => ({
                        ...prevState,
                        sub_item: res.data
                    }))
                }
            })
        }

    }, [location.state, itemId, subItemId, navigate])

    const createList = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('form-inventory-sub-item'))

            if (subItemId) {
                formdata.append('id', subItemId)
            }

            formdata.append('item_id', itemId)

            fetchData(subItemId ? INVENTORY_UPDATE : INVENTORY_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/inventory/${itemId}`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/inventory`} className="text-decoration-none" title="Choices">Inventory </Link>
                            {routeDetails && routeDetails.item && <>
                                <Link to={`/erp/inventory/${itemId}`} className="text-decoration-none" title={routeDetails.item.name}><i className="bi bi-chevron-double-right fs-14px"></i> {routeDetails.item.name} </Link>
                                <i className="bi bi-chevron-double-right fs-14px text-dark"></i> {subItemId ? 'Edit' : 'Add'} Sub Item
                            </>}
                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <button className="btn btn-blue btn-sm me-2" title="Item List" onClick={() => navigate(-1)}><i className="bi bi-list-task"></i> Item List</button>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="form-inventory-sub-item" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <InputField name="stock" type="number" value={data && data.stock} required />
                                    <FileField name="image" value={data && data.image} />
                                    <SubmitButton load={tinyloader} title={subItemId ? "Update" : "Save"} action={createList} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubItemAdd