import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GET_ACTIVE_CLIENT, GET_ACTIVE_PROJECT_MANAGER, PROJECT_CREATE } from "../../components/ApiRoutes";
import { InputField, SelectField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function ProjectAdd() {
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [projectManager, setProjectManager] = useState();
    const [client, setClient] = useState();

    useEffect(() => {
        fetchData(GET_ACTIVE_PROJECT_MANAGER, 'get', '', true, false, (res) => {
            if (res.status) {
                setProjectManager(res.data)
            }
        })
        fetchData(GET_ACTIVE_CLIENT, 'get', '', true, false, (res) => {
            if (res.status) {
                setClient(res.data)
            }
        })
    }, [])

    const createList = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('form-project-add'))

            fetchData(PROJECT_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/project`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/project`} className="text-decoration-none" title="Project">Projects </Link>
                            <i className="bi bi-chevron-double-right fs-14px text-dark"></i> Add Project
                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/project`} className="btn btn-blue btn-sm me-2" title="Project List"><i className="bi bi-list-task"></i> Project List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="form-project-add" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    {projectManager && client && <>
                                        <InputField name="name" required />
                                        <SelectField name="project_manager" required>
                                            {projectManager.map((user, index) => <option key={index} value={user.id}>{user.name}</option>)}
                                        </SelectField>
                                        <SelectField name="client" required>
                                            {client.map((user, index) => <option key={index} value={user.id}>{user.name}</option>)}
                                        </SelectField>
                                        <InputField name="address" required />
                                        <SubmitButton load={tinyloader} title="Save" action={createList} />
                                    </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectAdd