import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { DESIGN_CREATE, DESIGN_EDIT, DESIGN_UPDATE } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function DesignCreate() {
    const navigate = useNavigate();
    const designId = useParams().id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()

    useEffect(() => {
        if (designId) {
            fetchData(`${DESIGN_EDIT}/${designId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                }
            })
        }
    }, [designId])

    const createList = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let api_url = DESIGN_CREATE;
            let formdata = new FormData(document.getElementById('choiceListCreateForm'))
            if (designId) {
                api_url = DESIGN_UPDATE
                formdata.append('id', designId)
            }
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/design-approval`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/design-approval`} className="text-decoration-none" title="Design and Approval List">Design and Approval List </Link>
                            <i className="bi bi-chevron-double-right fs-14px text-dark"></i> {designId ? 'Edit' : 'Add'} Design and Approval
                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/design-approval`} className="btn btn-blue btn-sm me-2" title="Design and Approval List"><i className="bi bi-list-task"></i> Design and Approval List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="choiceListCreateForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="name" value={data && data.name} required />
                                    <SubmitButton type="submit" load={tinyloader} title={designId ? "Update" : "Save"} action={createList} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DesignCreate