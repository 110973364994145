import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { ActiveButton, DeactiveButton, DeleteButton, EditButton, PermissionButton, VerifyButton } from '../../components/ButtonHelper';
import { ConfirmationModal, fetchData, getPermission, getPermissionsModule, PermissionModal } from '../../components/Helper';
import { ACCOUNT_CHANGE_STATUS, ACCOUNT_LIST, MULTIPLE_ACCOUNT_DELETE, PERMISSION } from '../../components/ApiRoutes';
import Context from '../../components/Context';

function AccountList() {

    const accountType = useParams().account_type

    const navigate = useNavigate()
    const [context] = useContext(Context)
    const [userId, setUserId] = useState()
    const [permission, setPermission] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [access, setAccess] = useState({ view: false, add: false, edit: false, delete: false })
    const [permissionModule, setPermissionModule] = useState()
    const [multiUserId] = useState([])

    useEffect(() => {
        multiUserId.splice(0, multiUserId.length)
    }, [context, accountType])

    useEffect(() => {
        let module = ''
        if (accountType === 'admin') module = 'user_management__admin'
        else if (accountType === 'project-manager') module = 'user_management__project_manager'
        else if (accountType === 'client') module = 'user_management__client'

        setAccess(getPermission(context, module))
        setPermissionModule(getPermissionsModule(context, accountType))

        if (document.getElementById("deleteUser")) document.getElementById("deleteUser").disabled = true
    }, [context, accountType])

    const [dt] = useState({
        dt_url: `${ACCOUNT_LIST}?type=${accountType}`,
        dt_name: 'account_list',
        dt_order: [[1, 'desc']],
        dt_column: [
            { data: "id", name: 'id', title: "", sortable: false, searchable: false },
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "name", name: 'name', title: "Name" },
            { data: "email", name: 'email', title: "Email" },
            { data: "phone", name: 'phone', title: "Phone" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    let module = ''
                    if (accountType === 'admin') module = 'user_management__admin'
                    else if (accountType === 'project-manager') module = 'user_management__project_manager'
                    else if (accountType === 'client') module = 'user_management__client'
                    createRoot(td).render(
                        context && context.permissions && context.permissions[module] && context.permissions[module].includes('delete') && <input className="delete-user" type="checkbox" name='user_id[]' value={records.id} defaultChecked={multiUserId.includes(records.id)} onChange={checkUser} />
                    )
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <span className="text-green fw-medium">Active</span>}
                        {records.status === "inactive" && <span className="text-danger fw-medium">Inactive</span>}
                        {records.status === "pending" ? <span className="text-warning fw-medium">Pending</span> : ''}
                    </>)
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    let module = ''
                    if (accountType === 'admin') module = 'user_management__admin'
                    else if (accountType === 'project-manager') module = 'user_management__project_manager'
                    else if (accountType === 'client') module = 'user_management__client'

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {context && context.permissions && context.permissions[module] && <>
                                {context.auth && context.auth.role_id === 1 && records.status === "pending" && <VerifyButton action={() => handleChangeStatus('verify-email', records.id)} />}
                                {context.permissions[module].includes('edit') && <>
                                    {records.status === "inactive" && <ActiveButton action={() => handleChangeStatus('active', records.id)} />}
                                    {records.status === "active" && <DeactiveButton action={() => handleChangeStatus('inactive', records.id)} />}
                                    <PermissionButton modal_id="#permissionModal" action={() => getPermissions(records.id)} />
                                    <EditButton action={() => navigate(`/erp/user-management/${records.role.slug}/edit/${records.id}`)} />
                                </>}
                                {context.permissions[module].includes('delete') && <DeleteButton modal_id="#userDeleteModal" action={() => setUserId(records.id)} />}
                            </>}
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: () => {
            let module = ''
            if (accountType === 'admin') module = 'user_management__admin'
            else if (accountType === 'project-manager') module = 'user_management__project_manager'
            else if (accountType === 'client') module = 'user_management__client'
            createRoot(document.getElementById("dt-custom-button")).render(
                context && context.permissions && context.permissions[module] && context.permissions[module].includes('delete') && <button type="button" id="deleteUser" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#userMultipleDeleteModal" disabled>Delete</button>
            )
        }
    })

    useEffect(() => {
        reloadUrlDataTable(dt, `${ACCOUNT_LIST}?type=${accountType}`)

    }, [dt, accountType])

    const checkAllCheck = () => {
        let view = [...document.querySelectorAll(`#form-user-permission .view[type='checkbox'`)].every(ele => ele.checked)
        let add = [...document.querySelectorAll(`#form-user-permission .add[type='checkbox'`)].every(ele => ele.checked)

        document.querySelector("#form-user-permission #view").checked = view
        document.querySelector("#form-user-permission #add").checked = add

        if (accountType !== 'client') {
            let edit = [...document.querySelectorAll(`#form-user-permission .edit[type='checkbox'`)].every(ele => ele.checked)
            let del = [...document.querySelectorAll(`#form-user-permission .delete[type='checkbox'`)].every(ele => ele.checked)

            document.querySelector("#form-user-permission #edit").checked = edit
            document.querySelector("#form-user-permission #delete").checked = del
        }
    }

    useEffect(() => {
        checkAllCheck()
    }, [permission, accountType])

    const handleChangeStatus = (status, id) => {
        fetchData(ACCOUNT_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        })
    }

    const getPermissions = (id) => {
        setPermission(false)
        setUserId(id)
        fetchData(`${PERMISSION}/${id}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setPermission(res.data)
            }
        }, false, false, false)
    }

    const savePermissions = (e) => {
        setTinyloader(true)

        let formdata = new FormData(document.getElementById('form-user-permission'))

        formdata.append('user_id', userId)

        fetchData(PERMISSION, 'POST', formdata, true, true, (res) => {
            setTinyloader(false)
        }, false, false, false)
    }

    const checkPermission = (e, type) => {
        document.querySelectorAll(`#form-user-permission .${type}[type='checkbox'`).forEach((ele) => {
            ele.checked = e.target.checked
        })
    }

    const checkUser = (e) => {
        let val = parseInt(e.target.value)
        if (e.target.checked) {
            multiUserId.push(parseInt(val))
        } else {
            multiUserId.splice(multiUserId.indexOf(val), 1)
        }
        document.getElementById("deleteUser").disabled = multiUserId.length === 0
    }

    const deleteMultipleUser = () => {
        fetchData(MULTIPLE_ACCOUNT_DELETE, 'POST', { id: multiUserId }, true, false, (res) => {
            if (res.status) {
                loadDataTable(dt)
            }
        }, false, false, false)
    }
    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">{_.startCase(accountType)}s</h6>
                        </div>

                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/user-management/${accountType}/create`} className="btn btn-blue btn-sm px-3 me-2" title={`Add New ${_.startCase(accountType)}`}><i className="bi bi-plus-circle-fill me-1"></i> Add New {_.startCase(accountType)}</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="account_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal modal_id="userDeleteModal" title="Delete User" action={() => handleChangeStatus('delete', userId)}>
                <p className="">Are you sure want to delete this user?</p>
            </ConfirmationModal>
            <ConfirmationModal modal_id="userMultipleDeleteModal" title="Delete User" action={deleteMultipleUser}>
                <p className="">Are you sure want to delete selected user?</p>
            </ConfirmationModal>

            <PermissionModal modal_id="permissionModal" load={tinyloader} title="Update Permission" size="lg" action={savePermissions}>
                <form id="form-user-permission">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Module</th>
                                <th scope="col"><input type="checkbox" id="view" onChange={(e) => checkPermission(e, 'view')} /> View</th>
                                <th scope="col"><input type="checkbox" id="add" onChange={(e) => checkPermission(e, 'add')} /> Add</th>
                                {accountType !== 'client' && <>
                                    <th scope="col"><input type="checkbox" id="edit" onChange={(e) => checkPermission(e, 'edit')} /> Edit</th>
                                    <th scope="col"><input type="checkbox" id="delete" onChange={(e) => checkPermission(e, 'delete')} /> Delete</th>
                                </>}
                            </tr>
                        </thead>
                        <tbody>
                            {permission && permissionModule && permissionModule.map((module, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{module.title}</td>
                                        {module.slug === 'dashboard' ? <td>{<input className='' type="checkbox" value="view" name={`permission[${module.slug}][]`} checked disabled />}</td> : <>
                                            <td>
                                                {module.permission.includes('view') && <>
                                                    {module.access && module.access.includes('view') ? <input type="checkbox" className="view" value="view" name={`permission[${module.slug}][]`} defaultChecked={permission[module.slug] && permission[module.slug].includes('view')} onChange={checkAllCheck} /> : <input type="checkbox" className="" value="view" name={`permission[${module.slug}][]`} checked={permission[module.slug] && permission[module.slug].includes('view') ? true : false} disabled />}
                                                </>}
                                            </td>
                                            <td>
                                                {module.permission.includes('add') && <>
                                                    {module.access && module.access.includes('add') ? <input type="checkbox" className="add" value="add" name={`permission[${module.slug}][]`} defaultChecked={permission[module.slug] && permission[module.slug].includes('add')} onChange={checkAllCheck} /> : <input type="checkbox" className="" value="add" name={`permission[${module.slug}][]`} checked={permission[module.slug] && permission[module.slug].includes('add') ? true : false} disabled />}
                                                </>}
                                            </td>
                                            {accountType !== 'client' && <>
                                                <td>
                                                    {module.permission.includes('edit') && <>
                                                        {module.access && module.access.includes('edit') ? <input type="checkbox" className="edit" value="edit" name={`permission[${module.slug}][]`} defaultChecked={permission[module.slug] && permission[module.slug].includes('edit')} onChange={checkAllCheck} /> : <input type="checkbox" className="" value="edit" name={`permission[${module.slug}][]`} checked={permission[module.slug] && permission[module.slug].includes('edit') ? true : false} disabled />}
                                                    </>}
                                                </td>
                                                <td>
                                                    {module.permission.includes('delete') && <>
                                                        {module.access && module.access.includes('delete') ? <input type="checkbox" className="delete" value="delete" name={`permission[${module.slug}][]`} defaultChecked={permission[module.slug] && permission[module.slug].includes('delete')} onChange={checkAllCheck} /> : <input type="checkbox" className="" value="edit" name={`permission[${module.slug}][]`} checked={permission[module.slug] && permission[module.slug].includes('edit') ? true : false} disabled />}
                                                    </>}
                                                </td>
                                            </>}
                                        </>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </form>
            </PermissionModal>
        </>
    )
}

export default AccountList