import { useEffect, useState } from "react";
import { PROJECT_TASK_CREATE, PROJECT_TASK_UPDATE } from "../../../components/ApiRoutes";
import { fetchData, initialFormState, ModalSection, validateForm } from "../../../components/Helper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ModalInputField, ModalSelectField, ModalSubmitButton } from "../../../components/FormHelper";
import { now } from "lodash";

export const CreateTask = (props) => {
    const [tinyloader, setTinyloader] = useState(false);
    const [dueDate, setDueDate] = useState(new Date());
    const [data, setData] = useState();
    const [operation, setOperationa] = useState("Create");
    const [formReset, setFormReset] = useState(false);

    useEffect(() => {
        if (props.data) {
            setData(props.data)
            setDueDate((props.data.due_date === "--") ? null : new Date(props.data.due_date))
            setOperationa("Update")
        }
    }, [props.data])

    useEffect(() => {
        document.getElementById('createTaskModal').addEventListener('hidden.bs.modal', function (event) {
            initialFormState("createTaskForm")
            setData("")
            setDueDate(new Date())
            setOperationa("Create")
            setFormReset(now)
        })
    }, [])

    const createList = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)

            let formdata = new FormData(document.getElementById('createTaskForm'))
            formdata.append('project_id', props.projectId)
            formdata.append('task_category', props.projectStep)

            if (operation === 'Update') {
                formdata.append('id', data.id)
            }

            fetchData(operation === 'Create' ? PROJECT_TASK_CREATE : PROJECT_TASK_UPDATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    props.reloadTable()
                    document.getElementById('form-project-add-task').click()
                }
            })
        }
    }

    return (
        <ModalSection title={`${operation} ${props.taskAction ? "Task" : "Sub Task"}`} modal_id="createTaskModal" size="lg" close_btn_id="form-project-add-task">
            <form action="#" method="post" className="needs-validation" id="createTaskForm" noValidate>
                {props.taskAction ? <>
                    <ModalSelectField name="task" reset={formReset} value={data && data.task_name} required>
                        {props.taskCategoryList && props.taskCategoryList.length > 0 && props.taskCategoryList.map((task, index) => <option key={index} value={task.name}>{task.name}</option>)}
                    </ModalSelectField>
                </> : <>
                    <ModalSelectField name="project_task_id" title="Task" reset={formReset} value={data && data.project_task_id} required>
                        {props.taskList && props.taskList.length > 0 && props.taskList.map((task, index) => <option key={index} value={task.id}>{task.task_name}</option>)}
                    </ModalSelectField>
                    <ModalInputField reset={formReset} name="task" title="Sub Task" value={data && data.task_name} required />
                </>}

                <div className="row mx-0 mb-4 custom-css">
                    <label htmlFor="date" className="col-sm-3 col-xxl-6 col-form-label">Due Date</label>
                    <div className="col-sm-9 col-lg-6 col-xxl-6">
                        <DatePicker
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)}
                            className="form-control"
                            dateFormatCalendar={"MMM yyyy"}
                            minDate={new Date()}
                            onChangeRaw={(e) => e.preventDefault()}
                            id="date"
                            name="date"
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            isClearable
                        />
                    </div>
                </div>
                <ModalSubmitButton title="Save" load={tinyloader} action={createList} />
            </form>
        </ModalSection>
    )
}