import { useEffect, useState, useContext } from "react"
import { PROJECT_CHOICES_LIST, PROJECT_CHOICES_UPDATE, PROJECT_CHOICES, GET_PROJECT_CHOICES_LIST, DELETE_PROJECT_CHOICES_QUESTION } from "../../../components/ApiRoutes"
import { ButtonSpinner, ConfirmationModal, fetchData, getPermission, noImageHandle } from '../../../components/Helper';
import Context from "../../../components/Context"
import { EditButton } from "../../../components/ButtonHelper";
import { useParams } from "react-router-dom";
import { now } from "lodash";

function ChoiceList(props) {
    const [context] = useContext(Context)
    const [choiceList, setChoiceList] = useState()
    const [projectChoiceList, setprojectChoiceList] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [edit, setEdit] = useState(false);
    const projectStep = useParams().project_step
    const [deleteQuestionId, setDeleteQuestionId] = useState()
    const [editData, setEditData] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "project_management__choice_list")), [context])

    useEffect(() => {
        setEdit(false)
    }, [projectStep])

    useEffect(() => {
        fetchData(`${GET_PROJECT_CHOICES_LIST}/${props.project.id}`, 'get', '', true, false, (res) => {
            if (res.status) {
                if(res.data.length > 0){
                    setChoiceList(res.data)
                }else{
                    setEditData(false)
                    editProjectChoices()
                }
            }
        }, false, false, false)
    }, [props.project.id, refresh])

    const closeAccordion = () => {
        document.querySelectorAll("#projectChoiceListForm [data-bs-toggle='collapse']").forEach((ele) => {
            if(ele.getAttribute('aria-expanded') === 'true'){
                ele.click()
            }
        });
    }
    const editProjectChoices = () => {
        closeAccordion()
        setEdit(true)
        fetchData(`${PROJECT_CHOICES}/${props.project.id}`, 'get', '', true, false, (res) => {
            if (res.status) {
                setprojectChoiceList(res.data)
            }
        }, false, false, false)

        fetchData(PROJECT_CHOICES_LIST, 'get', '', true, false, (res) => {
            if (res.status) {
                setChoiceList(res.data)
            }
        }, false, false, false)
    }

    const saveChoiceList = (e) => {
        setTinyloader(true)
        let formdata = new FormData(document.getElementById('projectChoiceListForm'))
        formdata.append('project_id', props.project.id)
        fetchData(PROJECT_CHOICES_UPDATE, 'POST', formdata, true, true, (res) => {
            setTinyloader(false)
            closeAccordion()
            setEdit(false)
            setRefresh(now)
        })
    }

    const deleteQuestion = (id) => {
        fetchData(`${DELETE_PROJECT_CHOICES_QUESTION}/${id}`, 'POST', '', true, true, (res) => {
            setRefresh(now)
        })
    }

    return (<>
    <div className="col-sm-12">
        {access.edit && !edit && props.modify &&
            <div className="col-sm-12 text-sm-end mb-4">
                <EditButton action={editProjectChoices} />
            </div>
        }
        <form id="projectChoiceListForm" action="#" method="post" className="needs-validation" noValidate>
            <div className="accordion accordion-flush" id="accordionExample">
                {choiceList && choiceList.map((choice, index) => {
                    return (
                        <div className="accordion-item border-0" key={index}>
                            <h2 className="accordion-header" id={`choice_heading_${choice.id}`}>
                                <button className={`accordion-button rounded-10 border-0 shadow-none mb-2 bg-grey collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#choice_collapse_${choice.id}`} aria-expanded={"false"} aria-controls={`#choice_collapse_${choice.id}`}>
                                    {choice.name}
                                </button>
                            </h2>
                            <div id={`choice_collapse_${choice.id}`} className={`accordion-collapse collapse`} aria-labelledby={`choice_heading_${choice.id}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="accordion" id={`choice_accordion_${choice.id}`}>
                                        {choice.question_with_answer.map((question, q_key) => {
                                            return (
                                                <div className="accordion-item border-0" key={q_key}>
                                                    <h2 className="accordion-header position-relative" id={`choice_heading_${choice.id}_${q_key}`}>
                                                        <button className={`accordion-button rounded-10 border-0 shadow-none mb-1 p-2 bg-grey collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#choice_collapse_${choice.id}_${q_key}`} aria-expanded={"false"} aria-controls={`#choice_collapse_${choice.id}_${q_key}`}>
                                                            <span className="">{question.question}</span>
                                                        </button>
                                                        {access.delete && !edit && <i className="bi bi-trash-fill fs-20px me-4 text-danger position-absolute top-50 end-0 translate-middle" title="Delete" style={{zIndex: 11}} data-bs-toggle="modal" data-bs-target="#questionDeleteModal" onClick={(e) => {e.preventDefault(); setDeleteQuestionId(question.id)}}></i>}
                                                    </h2>
                                                    <div id={`choice_collapse_${choice.id}_${q_key}`} className={`accordion-collapse collapse`} aria-labelledby={`choice_heading_${choice.id}_${q_key}`} data-bs-parent={`#choice_accordion_${choice.id}`}>
                                                        <div className="accordion-body">
                                                            <div className="row mx-0">
                                                                {edit ? <>
                                                                    {question.answer && question.answer.length > 0 && question.answer.map((option, a_key) => {
                                                                    return (
                                                                        <div className="col col-sm-6 col-xl-4 col-xxl-3" key={a_key}>
                                                                            <div className="form-check form-check-inline mb-5">
                                                                                <div className="mb-2">
                                                                                    <input
                                                                                        defaultChecked={projectChoiceList && projectChoiceList[choice.name] && projectChoiceList[choice.name][question.question] && projectChoiceList[choice.name][question.question]['option'] && projectChoiceList[choice.name][question.question]['option'].includes(option.answer)}
                                                                                        className="form-check-input" type={question.multiple_option ? 'checkbox' : 'radio'}
                                                                                        id={`choice_option_${choice.id}_${question.id}_${option.id}`}
                                                                                        name={`choice[${choice.name}][${question.question}][option][]`}
                                                                                        value={option.answer}
                                                                                        required
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={`choice_option_${choice.id}_${question.id}_${option.id}`}>
                                                                                        {option.answer}
                                                                                    </label>
                                                                                </div>
                                                                                {option.image && <div className="">
                                                                                    <input type="hidden" name={`choice[${choice.name}][${question.question}][${option.answer}]`} value={option.image} />
                                                                                    <img className="border border-1 rounded mmwh-200px" src={process.env.REACT_APP_ASSET_URL + option.image} alt="choice" onError={noImageHandle} />
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                </> : <>
                                                                {question.answer && question.answer.option && question.answer.option.length > 0 && question.answer.option.map((option, a_key) => {
                                                                    return (
                                                                        <div className="col col-sm-6 col-xl-4 col-xxl-3" key={a_key}>
                                                                            <div className="form-check form-check-inline mb-5">
                                                                                <div className="mb-2">
                                                                                    <span>{option}</span>
                                                                                </div>
                                                                                    {question.answer[option] &&
                                                                                        <div className="">
                                                                                            <img className="border border-1 rounded mmwh-200px" src={process.env.REACT_APP_ASSET_URL + question.answer[option]} alt="choice" onError={noImageHandle} />
                                                                                        </div>
                                                                                    }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                </>}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {choiceList && edit && (editData || (!editData && access.add)) && props.modify && <div className="mt-4">
                <button type="button" className="btn btn-blue w-120px px-4 me-2" title="Save" onClick={saveChoiceList}>
                    <ButtonSpinner load={tinyloader} btnName="Save" />
                </button>
            </div>}
        </form>
    </div>
    <ConfirmationModal modal_id="questionDeleteModal" title="Delete Question" action={() => deleteQuestion(deleteQuestionId)} />
    </>
    )
}

export default ChoiceList