import { useEffect, useState } from "react"
import { GET_SETTINGS } from "../../components/ApiRoutes"
import { fetchData, validateForm } from "../../components/Helper"

function Setting() {
    const [setting, setSetting] = useState()

    useEffect(() => {
        fetchData(GET_SETTINGS, 'get', '', true, false, (res) => {
            if(res.status && res.data){
                setSetting(res.data)
            }
        })
    }, [])

    const saveData = (e) => {
        if(validateForm(e)){
            let formData = new FormData(document.getElementById('settingsForm'))
            fetchData('update-settings', 'POST', formData, true, true, (res) => {})
        }
    }
    return (
        <>
        <section className="admin-wrapper">
            { setting && (
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-3">
                        <h5 className="m-0 heading-lines fw-medium pb-2">Settings</h5>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4">
                                <form id="settingsForm" action="#" method="post" className="needs-validation" noValidate>
                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Site
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="domain_name" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Domain Name:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="domain_name" name="domain_name" defaultValue={setting.domain_name} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="site_name" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Site Name:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="site_name" name="site_name" defaultValue={setting.site_name} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="site_url" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Site URL:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="site_url" name="site_url" defaultValue={setting.site_url} required/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="copyright" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Copyright:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="copyright" name="copyright" defaultValue={setting.copyright} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <label htmlFor="logo" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                Logo URL:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="logo" name="logo" defaultValue={setting.logo} required/>
                                            </div>
                                        </div>
                                    </fieldset>


                                    <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 bg-light">
                                        <legend className="fs-6 fw-medium text-blue">
                                            Weather
                                        </legend>
                                        <div className="mb-3 row mx-0">
                                            <label htmlFor="api_key" className="col-sm-3 col-xxl-2 col-form-label text-xxl-end">
                                                API Key:
                                            </label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="api_key" name="api_key" defaultValue={setting.api_key} required/>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="row mx-0 p-3 p-sm-4">
                                        <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                            <button type="button" className="btn btn-blue px-5" onClick={saveData} title="Save">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> )}
        </section>
        </>
    )
}

export default Setting