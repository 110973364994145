import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Aside from "./Aside";
import { useEffect } from "react";

export const Layout = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
        let token = localStorage.getItem('adb-token')
        if (!token && !props.hideHeader) {
            navigate('/login')
        }
    }, [navigate, props.hideHeader]);

    return (
        <>
            {!props.hideHeader && <Header />}
            {!props.hideHeader && <Aside />}
            <Outlet />
        </>
    )
}

export default Layout;