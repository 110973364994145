import { useLocation } from 'react-router';
import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "./Context";

const Aside = () => {
    const [context] = useContext(Context)
    const location = useLocation()

    return (
        <aside className="admin-aside">
            <div className="admin-logobox">
                <Link to="/" className="admin-brandbox" title="Aus Dream Built">
                    <img src="/images/logo.png" alt="ausdreambuilt - Logo" />
                </Link>
                <button type="button" className="btn btn-close mmwh-36px border-0 p-0 align-items-center justify-content-center" id="menuClose" title="Menu Close"></button>
                <hr />
            </div>

            <div className="admin-navbox" id="navtoggle">
                {context && context.permissions && <>
                    <div className="accordion-item">
                        <NavLink className={location.pathname.match('/erp/dashboard') || (location.pathname === '/erp/') ? "main-link active collapsed" : "main-link collapsed"} to="/erp/dashboard" title="Dashboard">
                            <i className="bi-speedometer" role="img" aria-label="Speedometer"></i>
                            Dashboard
                        </NavLink>
                    </div>

                    {((context.permissions.user_management__admin && context.permissions.user_management__admin.includes('view')) || (context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('view')) || (context.permissions.user_management__client && context.permissions.user_management__client.includes('view'))) && <>
                        <div className="accordion-item">
                            <a className={location.pathname.match('/erp/user-management/*') ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu1" aria-expanded={location.pathname.match('/erp/user-management/*') ? 'true' : 'false'} title="User Management">
                                <i className="bi-person-circle" role="img" aria-label="Person"></i>
                                User Management
                            </a>
                            <div id="submenu1" className={location.pathname.match('/erp/user-management/*') ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                                {context.permissions.user_management__admin && context.permissions.user_management__admin.includes('view') && <NavLink className={location.pathname.match('/erp/user-management/admin/*') ? 'sub-link active' : 'sub-link'} to="/erp/user-management/admin/accounts" title="Admin">Admin</NavLink>}
                                {context.permissions.user_management__project_manager && context.permissions.user_management__project_manager.includes('view') && <NavLink className={location.pathname.match('/erp/user-management/project-manager/*') ? 'sub-link active' : 'sub-link'} to="/erp/user-management/project-manager/accounts" title="Project Managers">Project Managers</NavLink>}
                                {context.permissions.user_management__client && context.permissions.user_management__client.includes('view') && <NavLink className={location.pathname.match('/erp/user-management/client/*') ? 'sub-link active' : 'sub-link'} to="/erp/user-management/client/accounts" title="Clients">Clients</NavLink>}
                            </div>
                        </div>
                    </>}

                    {((context.permissions.category_management__choice_list && context.permissions.category_management__choice_list.includes('view')) || (context.permissions.category_management__design_and_approval && context.permissions.category_management__design_and_approval.includes('view')) || (context.permissions.category_management__pre_construction && context.permissions.category_management__pre_construction.includes('view')) || (context.permissions.category_management__construction && context.permissions.category_management__construction.includes('view')) || (context.permissions.category_management__post_construction && context.permissions.category_management__post_construction.includes('view'))) && <>
                        <div className="accordion-item">
                            <a className={(location.pathname.match('/erp/choice/*') || location.pathname.match('/erp/design-approval/*') || location.pathname.match('/erp/pre-construction/*') || location.pathname.match('/erp/construction/*') || location.pathname.match('/erp/post-construction/*')) ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu3" aria-expanded={(location.pathname.match('/erp/choice/*') || location.pathname.match('/erp/design-approval/*') || location.pathname.match('/erp/pre-construction/*') || location.pathname.match('/erp/construction/*') || location.pathname.match('/erp/post-construction/*')) ? 'true' : 'false'} title="Category Management">
                                <i className="bi-stack" role="img" aria-label="Grid"></i>
                                Category Management
                            </a>
                            <div id="submenu3" className={(location.pathname.match('/erp/choice/*') || location.pathname.match('/erp/design-approval/*') || location.pathname.match('/erp/pre-construction/*') || location.pathname.match('/erp/construction/*') || location.pathname.match('/erp/post-construction/*')) ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                                {context.permissions.category_management__choice_list && context.permissions.category_management__choice_list.includes('view') && <NavLink className={location.pathname.match('/erp/choice/*') ? 'sub-link active' : 'sub-link'} to="/erp/choice" title="Choice List">Choice List</NavLink>}
                                {context.permissions.category_management__design_and_approval && context.permissions.category_management__design_and_approval.includes('view') && <NavLink className={location.pathname.match('/erp/design-approval/*') ? 'sub-link active' : 'sub-link'} to="/erp/design-approval" title="Design and Approval">Design and Approval</NavLink>}
                                {context.permissions.category_management__pre_construction && context.permissions.category_management__pre_construction.includes('view') && <NavLink className={location.pathname.match('/erp/pre-construction/*') ? 'sub-link active' : 'sub-link'} to="/erp/pre-construction" title="Pre-construction">Pre Construction</NavLink>}
                                {context.permissions.category_management__construction && context.permissions.category_management__construction.includes('view') && <NavLink className={location.pathname.match('/erp/construction/*') ? 'sub-link active' : 'sub-link'} to="/erp/construction" title="Construction">Construction</NavLink>}
                                {context.permissions.category_management__post_construction && context.permissions.category_management__post_construction.includes('view') && <NavLink className={location.pathname.match('/erp/post-construction/*') ? 'sub-link active' : 'sub-link'} to="/erp/post-construction" title="Post-construction">Post Construction</NavLink>}
                            </div>
                        </div>
                    </>}

                    {context.permissions.project_management__details && context.permissions.project_management__details.includes('view') && <>
                        <div className="accordion-item">
                            <NavLink className={location.pathname.match('/erp/project/*') ? "main-link active collapsed" : "main-link collapsed"} to="/erp/project" title="Project Management">
                                <i className="bi bi-buildings" role="img" aria-label="Book"></i>
                                Project Management
                            </NavLink>
                        </div>
                    </>}

                    {context.permissions.inventory && context.permissions.inventory.includes('view') &&
                        <div className="accordion-item">
                            <NavLink className={location.pathname.match('/erp/inventory') ? "main-link active collapsed" : "main-link collapsed"} to="/erp/inventory" title="Inventory">
                                <i className="bi bi-database" role="img" aria-label="Grid"></i>
                                Inventory
                            </NavLink>
                        </div>
                    }

                    {context.permissions.logs && context.permissions.logs.includes('view') &&
                        <div className="accordion-item">
                            <NavLink className={location.pathname.match('/erp/logs') ? "main-link  collapsed" : "main-link collapsed"} to="/erp/logs" title="Activity Logs">
                                <i className="bi bi-info-square" role="img" aria-label="Grid"></i>
                                Activity Logs
                            </NavLink>
                        </div>
                    }

                    {context.auth && context.auth.role_id === 1 &&
                         <div className="accordion-item">
                            <NavLink className={location.pathname.match('/erp/drive-management') ? "main-link  collapsed" : "main-link collapsed"} to="/erp/drive-management" title="Drive Management">
                                <i className="bi bi-folder2" role="img" aria-label="Grid"></i>
                                Drive Management
                            </NavLink>
                        </div>
                    }

                </>}
                
            </div>

            <div className="admin-copyrightsbox">{context && context.site && context.site.copyright} | v1.0.0</div>
        </aside>
    )
}

export default Aside
