import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { CHOICE_ANSWER_CREATE, CHOICE_ANSWER_EDIT, CHOICE_ANSWER_UPDATE, CHOICE_QUESTIONS_EDIT } from "../../components/ApiRoutes";
import { FileField, InputField, SubmitButton } from "../../components/FormHelper";
import { fetchData, validateForm } from "../../components/Helper";

function ChoiceAnswerListCreate() {
    const navigate = useNavigate();
    const choiceId = useParams().choice_id
    const questionId = useParams().question_id
    const answerId = useParams().answer_id
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState()
    const [routeDetails, setRouteDetails] = useState()

    useEffect(() => {
        if (choiceId && questionId && !answerId) {
            fetchData(`${CHOICE_QUESTIONS_EDIT}/${questionId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setRouteDetails({ choice: res.data.choice, choice_question: res.data })
                }
            })
        }

        if (answerId) {
            fetchData(`${CHOICE_ANSWER_EDIT}/${answerId}`, 'get', '', true, false, (res) => {
                if (res.status) {
                    setData(res.data)
                    setRouteDetails({ choice: res.data.choice, choice_question: res.data.choice_question });
                }
            })
        }

    }, [choiceId, questionId, answerId, navigate])

    const createList = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let api_url = CHOICE_ANSWER_CREATE;
            let formdata = new FormData(document.getElementById('choiceListCreateForm'))
            if (answerId) {
                api_url = CHOICE_ANSWER_UPDATE
                formdata.append('id', answerId)
            }
            formdata.append('choice_id', choiceId)
            formdata.append('question_id', questionId)
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    navigate(`/erp/choice/${choiceId}/${questionId}`)
                }
            })
        }
    }
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">
                            <Link to={`/erp/choice`} className="text-decoration-none" title="Choice List">Choice List </Link>
                            {routeDetails && routeDetails.choice && <>
                                <Link to={`/erp/choice/${choiceId}`} className="text-decoration-none" title={routeDetails.choice.name}><i className="bi bi-chevron-double-right fs-14px text-dark"></i>  {routeDetails.choice.name} </Link>
                            </>}
                            {routeDetails && routeDetails.choice_question && <>
                                <Link to={`/erp/choice/${choiceId}/${questionId}`} className="text-decoration-none" title={routeDetails.choice_question.question}><i className="bi bi-chevron-double-right fs-14px text-dark"></i>  {routeDetails.choice_question.question} </Link>
                            </>}
                            <i className="bi bi-chevron-double-right fs-14px text-dark"></i> {answerId ? 'Edit' : 'Add'} Option

                        </h6>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/erp/choice/${choiceId}/${questionId}`} className="btn btn-blue btn-sm me-2" title="Option List"><i className="bi bi-list-task"></i> Option List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form id="choiceListCreateForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    <InputField name="option" value={data && data.answer} required />
                                    <FileField name="image" value={data && data.image} />
                                    <SubmitButton type="submit" load={tinyloader} title={answerId ? "Update" : "Save"} action={createList} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChoiceAnswerListCreate