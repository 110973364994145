import { useEffect, useState } from "react"
import { GENERATE_DRIVE_TOKEN } from "../../components/ApiRoutes"
import { fetchData } from "../../components/Helper"

function DriveManangement() {
    const [token, setToken] = useState(false)

    useEffect(() => {
        fetchData(GENERATE_DRIVE_TOKEN, 'POST', '', true, true, (res) => {
            if (res.status) {
                setToken(res.data)
            }
        })
    }, [])
    
    return (
        <>
        <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Drive Management</h6>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                {token &&
                                    <div className="card-body">
                                        <iframe src={process.env.REACT_APP_API_URL + "drive-manager/" + token} style={{height: "85vh", width: "100%"}} title="Drive"></iframe>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
  
export default DriveManangement