import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PROJECT_LOGS } from "../../../components/ApiRoutes";
import DataTables, { reloadUrlDataTable } from "../../../components/Datatables";

function Logs(props) {
    const projectId = useParams().project_id

    const [dt] = useState({
        dt_url: `${PROJECT_LOGS}/${projectId}`,
        dt_name: 'project_log_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "action_perform_id", name: 'action_perform_id', title: "Action Perform ID" },
            { data: "action_perform_by", name: 'action_perform_by', title: "Action Perform By" },  
            { data: "action_perform_on", name: 'action_perform_on', title: "Action Perform On" },
            { data: "message", name: 'message', title: "Description" },  
            { data: "created_at", name: 'created_at', title: "Log Time" },
        ],
    })

    useEffect(() => reloadUrlDataTable(dt, `${PROJECT_LOGS}/${projectId}`), [dt, projectId])

    return (
        <div className="table-responsive p-1">
            <DataTables dt_name={`project_log_list`} />
        </div>
    )
}

export default Logs;