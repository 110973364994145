import { useState ,useContext } from "react";
import { PasswordField, SubmitButton } from "../../components/FormHelper";
import { fetchData, initialFormState, validateForm} from "../../components/Helper";
import Context from "../../components/Context";
import { CHANGE_PASSWORD} from "../../components/ApiRoutes";

function ChangePassword() {
    const [context] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);
    const userId = context && context.auth && context.auth.id;

    const change_passowrd = (e) => {
        if (validateForm(e)) {
           setTinyloader(true)

            let api_url = CHANGE_PASSWORD;
            let formdata = new FormData(document.getElementById('changepasswordForm'))
           
            if (userId) {
                api_url = CHANGE_PASSWORD
                formdata.append('user_id', userId);
            } 
            
            fetchData(api_url, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    initialFormState("changepasswordForm")
                }
            }) 
          
        }
    }

    return (
        
        <section className="admin-wrapper">
        <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
                <div className="col mb-3">
                    <h6 className="m-0 heading-lines fw-medium pb-2">Change Password</h6>
                </div>
            </div>
            <div className="row g-2 g-sm-3 g-lg-4">
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body p-sm-4 p-xl-5">
                            <form id="changepasswordForm" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate> 
                                <PasswordField name="password"  required />
                                <PasswordField name="new_password" required />
                                <PasswordField name="confirm_password"  required />
                                <SubmitButton load={tinyloader} title={userId ? "Update" : "Save" } action={change_passowrd} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default ChangePassword;