import { useContext, useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import { useParams } from "react-router-dom"
import { GET_ACTIVE_CONSTRUCTION_LIST, GET_ACTIVE_DESIGN_LIST, GET_ACTIVE_POST_CONSTRUCTION_LIST, GET_ACTIVE_PRE_CONSTRUCTION_LIST, PROJECT_TASK_BY_CATEGORY, PROJECT_TASK_CHANGE_STATUS, PROJECT_TASK_LIST } from "../../../components/ApiRoutes"
import { AttachmentButtonModal, CancelButton, CompleteButton, DeleteButton, EditButtonModal, HoldButton, InProgressButton, NoteButtonModal } from "../../../components/ButtonHelper"
import DataTables, { reloadUrlDataTable } from "../../../components/Datatables"
import Context from "../../../components/Context"
import { CreateTask } from "../Components/CreateTask"
import { Note } from "../Components/Note"
import { Attachment } from "../Components/Attachment"
import { ConfirmationModal, fetchData } from "../../../components/Helper"
import { now } from "lodash"
import ChangeStatus from "../Components/ChangeStatus"
import FilterTaskList from "../Components/FilterTaskList"

const TaskList = (props) => {
    const [context] = useContext(Context);
    const projectId = useParams().project_id
    const projectStep = useParams().project_step
    const [reloadTable, setReloadTable] = useState();
    const [taskList, setTaskList] = useState();
    const [taskCategoryList, setTaskCategoryList] = useState();
    const [taskFunType, setTaskFunType] = useState();
    const step = `project_management__` + projectStep.replace(/-/g, '_')
    const [taskData, setTaskData] = useState()
    const [taskAction, setTaskAction] = useState(true)
    const [taskStatus, setTaskStatus] = useState('')
    const [filter, setFilter] = useState('')
    const root = useRef()

    const showButton = context && context.permissions && `${context.permissions[step]}`

    const [dt] = useState({
        dt_url: `${PROJECT_TASK_LIST}/${projectId}/${projectStep}`,
        dt_name: `project_task_list`,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "created_at", name: 'created_at', title: "Created" },
            { data: "created_user.name", name: 'createdUser.name', title: "Created By" },
            { data: "type", name: 'project_task_id', title: "Type" },
            { data: "task_name", name: 'task_name', title: "Task/SubTask" },
            { data: "due_date", name: 'due_date', title: "Due Date" },
            { data: "notes_available", name: '', title: "Notes/Attachments Availables", sortable: false, searchable: false, visible: context && context.permissions && ((context.permissions.attachments && context.permissions.attachments.includes('view')) || (context.permissions.notes && context.permissions.notes.includes('view'))) ? true : false },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {context && context.permissions && <>
                            {context.permissions.notes && context.permissions.notes.includes('view') && <span role="button" data-bs-toggle="modal" data-bs-target="#noteModal" className={`d-flex ${records.notes_available ? 'text-success' : 'text-danger'}`} onClick={() => openModal(records, "note")}>{records.notes_available} {records.notes_available > 1 ? 'Notes' : 'Note'} Availables</span>} 
                            {context.permissions.attachments && context.permissions.attachments.includes('view') && <span role="button" data-bs-toggle="modal" data-bs-target="#attachmentModal" className={`d-flex ${records.attachments_available ? 'text-success' : 'text-danger'}`} onClick={() => openModal(records, "attachment")}>{records.attachments_available} {records.attachments_available > 1 ? 'Attachments' : 'Attachment'} Available</span>} 
                        </>}
                    </>)
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "pending" && <span className="text-warning fw-medium">Pending</span>}
                        {records.status === "in_progress" && <span className="text-info fw-medium">In Progress</span>}
                        {records.status === "hold" && <span className="fw-medium">Hold</span>}
                        {records.status === "completed" && <span className="text-success fw-medium">Completed</span>}
                        {records.status === "cancel" && <span className="text-danger fw-medium">Cancelled</span>}
                    </>)
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {context && context.permissions &&
                                <>
                                    {showButton.includes('edit') && props.modify && records.edit && <>
                                        {['pending', 'hold', 'completed', 'cancel'].includes(records.status) && <InProgressButton action={() => handleChangeStatus('in_progress', records.id)} />}
                                        {records.status === 'in_progress' && <HoldButton action={() => handleChangeStatus('hold', records.id)} />}
                                        {(records.status === 'in_progress' || records.status === 'hold') && <CompleteButton action={() => handleChangeStatus('completed', records.id)} />}
                                        {(records.status !== "cancel" && records.status !== "completed") && <>
                                            <CancelButton modal_id="#taskCancelModal" action={() => setTaskData(records)} />
                                            <EditButtonModal modal_id="#createTaskModal" action={() => {setTaskData(records); setTaskAction(records.project_task_id ? false : true)}} />
                                        </>}
                                    </>}

                                    {context.permissions.notes && context.permissions.notes.includes('view') && <NoteButtonModal modal_id="#noteModal" action={() => openModal(records, "note")} />}
                                    {context.permissions.attachments && context.permissions.attachments.includes('view') && <AttachmentButtonModal modal_id="#attachmentModal" action={() => openModal(records, "attachment")} />}
                                    {showButton.includes('delete') && props.modify && <DeleteButton modal_id="#taskDeleteModal" action={() => setTaskData(records)} />}
                                </>}
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: (e) => {
            root.current.render(<>
            <div className="d-sm-flex">
                <FilterTaskList reload={e} module="" projectId={projectId} projectStep={projectStep} reloadTable={reloadTable} action={(id) => setFilter(id)} action2={(type) => setFilter(type)} />
                <ChangeStatus module="" allTitle="Status" action={(status) => setTaskStatus(status)} />
                </div>
            </>)
        }
    })

    useEffect(() => {
        fetchData(`${PROJECT_TASK_BY_CATEGORY}/${projectId}/${projectStep}?add_sub_task=yes`, 'get', '', true, false, (res) => {
            if (res.status) {
                setTaskList(res.data)
            }
        }, false, false, false)
        
        if(reloadTable) dt.dt_filter(reloadTable)
        reloadUrlDataTable(dt, `${PROJECT_TASK_LIST}/${projectId}/${projectStep}?status=${taskStatus}&${filter}`)
    }, [dt, projectId, projectStep, reloadTable, taskStatus, filter])

    useEffect(() => {
        if (projectStep) {
            let api_url = ''
            if (projectStep === 'design-and-approval') {
                api_url = GET_ACTIVE_DESIGN_LIST
            } else if (projectStep === 'pre-construction') {
                api_url = GET_ACTIVE_PRE_CONSTRUCTION_LIST
            } else if (projectStep === 'construction') {
                api_url = GET_ACTIVE_CONSTRUCTION_LIST
            } else if (projectStep === 'post-construction') {
                api_url = GET_ACTIVE_POST_CONSTRUCTION_LIST
            }
            fetchData(api_url, 'get', '', true, false, (res) => {
                if (res.status) {
                    setTaskCategoryList(res.data)
                }
            })
        }

        if(document.getElementById("dt-custom-button")) root.current = createRoot(document.getElementById("dt-custom-button"))
    }, [projectStep, projectId])

    const openModal = (data, type) => {
        setTaskFunType(type)
        setTaskData(data)
    }

    const handleChangeStatus = (status, id) => {
        fetchData(PROJECT_TASK_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            setReloadTable(now)
        })
    }

    return (
        <>
            <style jsx="true">{`
                #subTaskModal .modal-body {
                    min-height: 310px;
                }
            `}</style>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div className="text-orange mb-1"></div>
                <div className="">
                    {showButton.includes('add') && props.modify &&
                        <div className="text-end">
                            <button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createTaskModal" onClick={() => {setTaskData(null); setTaskAction(true)}} title="Create Task"><i className="bi bi-plus-circle-fill me-1"></i> Create Task</button>
                            <button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-1" data-bs-toggle="modal" data-bs-target="#createTaskModal" onClick={() => {setTaskData(null); setTaskAction(false)}} title="Create Sub Task"><i className="bi bi-plus-circle-fill me-1"></i> Create Sub Task</button>
                        </div>
                    }
                </div>
            </div>

            <div className="p-1">
                <DataTables dt_name={`project_task_list`} />
            </div>

            <CreateTask data={taskData} projectId={projectId} projectStep={projectStep} reloadTable={() => setReloadTable(now)} taskList={taskList} taskCategoryList={taskCategoryList} taskAction={taskAction} />
            <Note taskId={taskData && taskData.id} projectId={projectId} projectStep={projectStep} reloadTable={() => setReloadTable(now)} type={taskFunType} modify={props.modify} permissions={context && context.permissions && context.permissions.notes} />
            <Attachment taskId={taskData && taskData.id} projectId={projectId} projectStep={projectStep} reloadTable={() => setReloadTable(now)} type={taskFunType} modify={props.modify} />

            <ConfirmationModal modal_id="taskDeleteModal" title="Delete Task" action={() => handleChangeStatus('delete', taskData.id)}>
                <p className="">Are you sure want to delete this {taskData && taskData.project_task_id ? "Sub Task" : "Task"}?</p>
            </ConfirmationModal>
            <ConfirmationModal modal_id="taskCancelModal" title="Cancel Project" operation="Confirm" action={() => handleChangeStatus('cancel', taskData.id)}>
                <p className="">Are you sure want to cancel this {taskData && taskData.project_task_id ? "Sub Task" : "Task"}?</p>
            </ConfirmationModal>
        </>
    )
}

export default TaskList