import { createRoot } from 'react-dom/client'
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import Datatables, { loadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { ActiveButton, DeactiveButton, DeleteButton, DragButton, EditButton } from '../../components/ButtonHelper';
import { ConfirmationModal, fetchData, getPermission } from '../../components/Helper';
import { POST_CONSTRUCTION_CHANGE_ORDER, POST_CONSTRUCTION_CHANGE_STATUS, POST_CONSTRUCTION_LIST } from '../../components/ApiRoutes';
import Context from '../../components/Context';

function PostConstructionList() {
    const navigate = useNavigate()
    const [deleteItem, setDeleteItem] = useState()
    const [context] = useContext(Context)
    const [access, setAccess] = useState({view: false, add: false, edit: false, delete: false})

    useEffect(() => setAccess(getPermission(context, "category_management__post_construction")), [context])

    const [dt] = useState({
        dt_url: POST_CONSTRUCTION_LIST,
        dt_name: 'post_construction_list',
        dt_order: [[1, 'asc']],
        dt_rowReorder: {
            url: POST_CONSTRUCTION_CHANGE_ORDER,
        },
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "order", name: 'order', title: "#Order", visible: false },
            { data: "name", name: 'name', title: "Name" },
            { data: "status", name: 'status', title: "Status" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records.status === "active" && <span className="text-green fw-medium">Active</span>}
                        {records.status === "inactive" && <span className="text-danger fw-medium">Inactive</span>}
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    let permission = getPermission(context, "category_management__post_construction")

                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            {permission.edit && <>
                                {records.status === "active" ? <DeactiveButton action={() => handleChangeStatus('inactive', records.id)} /> : <ActiveButton action={() => handleChangeStatus('active', records.id)} />}
                                <EditButton action={() => navigate(`/erp/post-construction/edit/${records.id}`)} />
                            </>}

                            {permission.delete && <DeleteButton modal_id="#confirmationModal" action={() => setDeleteItem(records.id)} />}
                            {permission.edit && <DragButton />}
                        </div>
                    </>)
                }
            }
        ],
    })

    useEffect(() => {
        reloadUrlDataTable(dt, POST_CONSTRUCTION_LIST)
    }, [dt])

    const handleChangeStatus = (status, id) => {
        fetchData(POST_CONSTRUCTION_CHANGE_STATUS, 'POST', { 'id': id, 'status': status }, true, false, (res) => {
            loadDataTable(dt)
        }, false, false, false)
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col mb-3">
                            <h6 className="m-0 heading-lines fw-medium pb-2">Post Construction List</h6>
                        </div>
                        <div className="col-auto mb-2">
                            {access.add && <Link to={`/erp/post-construction/create`} className="btn btn-blue btn-sm px-3 me-2" title="Add Post Construction"><i className="bi bi-plus-circle-fill me-1"></i> Add Post Construction</Link>}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="post_construction_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmationModal action={() => handleChangeStatus('delete', deleteItem)} />
        </>
    )
}

export default PostConstructionList