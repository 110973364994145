import _ from "lodash"
import { useEffect, useState } from "react"
import { ButtonSpinner, showPassword } from "./Helper"

export const PasswordField = (props) => {

    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5 position-relative">
                <input type="password" className="form-control no-validate-icon" id={props.name} name={props.name} required={props.required ? true : false} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
                <button type="button" onClick={(e) => showPassword(e, props.name)} className="position-absolute me-3 top-0 end-0 pt-1">
                    <i className="bi-eye fs-5 pe-none"></i>
                </button>
            </div>
        </div>
    )
}

export const InputField = (props) => {

    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5">
                <input type={props.type ?? 'text'} className="form-control" id={props.name} name={props.name} defaultValue={props.value ?? ''} required={props.required ? true : false} disabled={props.disabled ? true : false} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}

export const SubmitButton = (props) => {
    return (
        <div className="row mx-0">
            <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                <button type={props.type ?? "button"} className={`btn btn-${props.bgColor ?? 'blue'} w-120px px-4 me-2`} title={props.title} onClick={props.action}>
                    <ButtonSpinner load={props.load} btnName={props.title} />
                </button>
            </div>
        </div>
    )
}

export const FileField = (props) => {
    const [image, setImage] = useState([])
    const [oldImage, setOldImage] = useState([])
    const [mergeImage, setMergeImage] = useState([])

    useEffect(() => {
        if(props.value){
            setOldImage(_.split(props.value, ','))
        }
    }, [props.value])

    const preViewImage = async (e) => {
        if(props.multiple){
            if(e.target.files && e.target.files.length > 0){
                for (let index = 0; index < e.target.files.length; index++) {
                    let img = await BlobToBase64(e.target.files[index])
                    setImage(prevState => ([...prevState, img]))
                }
    
                let allImage = [...mergeImage, ...e.target.files]
                await setMergeImage([])
                await setMergeImage(allImage)
                document.getElementById(props.name).files = await fileupload(allImage)
            }
        }else{
            await setOldImage([])
            await setImage([])
            let img = await BlobToBase64(e.target.files[0])
            setImage(prevState => ([...prevState, img]))
        }
    }

    const fileupload = async (files, remove_index = -1) => {
        let file_list = new ClipboardEvent("").clipboardData || new DataTransfer()
        for (let i = 0; i < files.length; i++) {
            if(i !== remove_index){
                let file = new File([files[i]], files[i].name, files[i])
                file_list.items.add(file)
            }
        }
        return file_list.files
    }

    const removeImage = async(index, upload = false) => {
        if(upload){
            let files = document.getElementById(props.name).files
            document.getElementById(props.name).files = await fileupload(files, index)

            let img = image
            await setImage([])
            img.splice(index, 1);
            setImage(img)

            if(props.multiple){
                let merge_image = mergeImage
                await setMergeImage([])
                merge_image.splice(index, 1);
                setMergeImage(merge_image)
            }
        }else{
            let img = oldImage
            await setOldImage([])
            img.splice(index, 1);
            setOldImage(img)
        }
    }

    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5">
                <input type='hidden' className="form-control" id={`old_${props.name}`} value={_.join(oldImage, ',')} name={`old_${props.name}`} />
                <input type='file' className="form-control" id={props.name} name={props.name} required={props.required ? true : false} multiple={props.multiple ? true : false} disabled={props.disabled ? true : false} onChange={preViewImage} accept=".png,.jpg,.jpeg" />
                <div className="form-fileview mt-1">
                {
                    oldImage.map((item, index) => {
                        return (
                            <label key={index} className="table-imgbox table-img60 ratio ratio-1x1 overflow-hidden mt-2 me-2 form-image">
                                <img src={process.env.REACT_APP_ASSET_URL + item} alt="Example" />
                                <button className="image_remove_button" type="button" title="Remove" onClick={() => removeImage(index)}>&times;</button>
                            </label>
                        )
                    })
                }

                {
                    image.map((item, index) => {
                        return (
                            <label key={index} className="table-imgbox table-img60 ratio ratio-1x1 overflow-hidden mt-2 me-2 form-image">
                                <img src={item} alt="Example" />
                                <button className="image_remove_button" type="button" title="Remove" onClick={() => removeImage(index, true)}>&times;</button>
                            </label>
                        )
                    })
                }
                </div>
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}

export const  PreViewImage= async (e) => {
    return await BlobToBase64(e.target.files[0])
}

export const BlobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const SelectField = (props) => {
    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-2 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-5">
                <select className="form-select" id={props.name} name={props.name} defaultValue={props.value} required={props.required ? true : false}>
                    <option className="text-muted" value="">Select {props.title ?? _.startCase(props.name)}</option>
                    {props.children}
                </select>
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}

export const ModalInputField = (props) => {
    const [value, setValue] = useState(props.value ?? '')

    useEffect(() => setValue(''), [props.reset])
    useEffect(() => setValue(props.value ?? ''), [props.value])

    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-6 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-6">
                <input type={props.type ?? 'text'} className="form-control" id={props.name} name={props.name} value={value} onChange={(e) => setValue(e.target.value)} required={props.required ? true : false} disabled={props.disabled ? true : false} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}

export const ModalSubmitButton = (props) => {
    return (
        <div className="row mx-0">
            <div className="col-6 offset-sm-3 offset-xxl-6">
            <button type={props.type ?? "button"} className={`btn btn-${props.bgColor ?? 'blue'} w-120px px-4 me-2`} title={props.title} onClick={props.action}>
                <ButtonSpinner load={props.load} btnName={props.title} />
            </button>
            </div>
        </div>
    )
}

export const ModalSelectField = (props) => {
    const [value, setValue] = useState(props.value ?? '')
    
    useEffect(() => setValue(''), [props.reset])
    useEffect(() => setValue(props.value), [props.value])

    return (
        <div className="mb-4 row mx-0">
            <label htmlFor={props.name} className="col-sm-3 col-xxl-6 col-form-label">
                {props.title ?? _.startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className="col-sm-9 col-lg-6 col-xxl-6">
                <select className="form-select" id={props.name} name={props.name} value={value} onChange={(e) => setValue(e.target.value)} required={props.required ? true : false}>
                    <option className="text-muted" value="">Select {props.title ?? _.startCase(props.name)}</option>
                    {props.children}
                </select>
                <div className="invalid-feedback">The {_.startCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}