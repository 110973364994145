import _ from "lodash";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useParams } from "react-router-dom";
import { PROJECT_REPORT } from "../../../components/ApiRoutes";
import DataTables, { reloadUrlDataTable } from "../../../components/Datatables";
import { downloadBase64File, generateId, srcToBase64 } from "../../../components/Helper";
function Report(props) {
    const projectId = useParams().project_id

    const [dt] = useState({
        dt_url: `${PROJECT_REPORT}/${projectId}`,
        dt_name: 'project_report_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "value", name: 'value', title: "Attachments", class: "w-50" },
            { data: "user.name", name: 'user.name', title: "Created By" },
            { data: "project_task.task_name", name: 'projectTask.task_name', title: "Task Name",},
            { data: "project_task.task_category", name: 'projectTask.task_category', title: "Task Category" }, 
        ],

        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                            {records.value.map((value, key) => {
                                return (
                                    <div key={key} className="table-imgbox table-img100 position-relative me-2 mb-2" role="button" data-bs-target="#zoomImageModal" data-bs-toggle="modal" title="Zoom Image" data-previewimg={process.env.REACT_APP_ASSET_URL + records.image}>
                                        {['jpeg', 'jpg', 'png'].includes(value.split('.').pop()) && <img className="rounded h-100" src={process.env.REACT_APP_ASSET_URL + value} alt="attachment" />}
                                        {['ogx', 'oga', 'ogv', 'mp4', 'mov', 'ogg', 'webm'].includes(value.split('.').pop()) && <video className="rounded h-100" src={process.env.REACT_APP_ASSET_URL + value} controls />}
                                        {['doc', 'docx'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/google-docs.png" alt="attachment" />}
                                        {['xls', 'xlsx'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/excel-file.png" alt="attachment" />}
                                        {['pdf'].includes(value.split('.').pop()) && <img className="rounded m-2 h-75" src="/images/pdf.png" alt="attachment" />}

                                        <button className="image-download-btn btn btn-sm btn-secondary position-absolute top-0 end-0 action-btn" type="button" title="Download" onClick={(e) => downloadAttachment(e, process.env.REACT_APP_ASSET_URL + value)}>
                                            <i className="bi bi-download"></i>
                                        </button>
                                    </div>
                                )
                            })}
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <span>{records.project_task && records.project_task.task_category && _.startCase(records.project_task.task_category)}</span>
                    </>)
                }
            },
        ]
    })

    useEffect(() => reloadUrlDataTable(dt, `${PROJECT_REPORT}/${projectId}`), [dt, projectId])

    const downloadAttachment = (e, url) => {
        e.target.classList.add("disabled")
        let name = generateId(25)
        srcToBase64(url, res => {
            e.target.classList.remove("disabled")
            downloadBase64File(res, name)
        })
    }

    return (
        <div className="table-responsive p-1">
            <DataTables dt_name={`project_report_list`} />
        </div>
    )
}

export default Report;