import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../components/Context'
import { ButtonSpinner, fetchData, scrollbarSetToTop, showAlert, showPassword, validateForm } from '../components/Helper'

const Login = () => {

    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)
    const [token] = useState(localStorage.getItem('adb-token'))
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
        scrollbarSetToTop();
        if (token && context && context.auth) {
            navigate("/erp/dashboard");
        }
        let block_user = localStorage.getItem('block_user')
        if(block_user) {
            localStorage.removeItem('block_user')
            showAlert(JSON.parse(block_user))
        }
    }, [token, context, navigate])

    const submitLoginForm = (e) => {
        let formdata = new FormData(document.getElementById('login-form'));

        if (validateForm(e)) {
            setTinyloader(true)

            fetchData('login', 'POST', formdata, false, true, (res) => {
                setTinyloader(false)

                if (res.status) {
                    localStorage.setItem("adb-token", res.data.access_token);

                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                        permissions: res.data.permissions
                    }))

                    navigate("/erp/dashboard");
                } else {
                    if (res.errors && (res.errors.password || res.errors.email)) {
                        showAlert({ message: "These credentials do not match our records." })
                    }
                }
            })
        }
    }

    return (
        <section className="login-section">
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white d-flex align-items-center">
                        <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="login-form">
                            <h4 className="heading-lines mb-4 mt-0">ERP Login</h4>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingEmail" name="email" placeholder="name@example.com" required />
                                <label htmlFor="floatingEmail">Email address*</label>
                            </div>
                            <div className="form-floating mb-3 position-relative">
                                <input type="password" className="form-control no-validate-icon" id="floatingPassword" name="password" placeholder="*********" required />
                                <label htmlFor="floatingPassword">Password*</label>
                                <em className='small text-success'>Please enter minimum 8 characters.</em>
                                <button type="button" onClick={(e) => showPassword(e, "floatingPassword")} className="position-absolute me-3 top-0 end-0 pt-3">
                                    <i className="bi-eye fs-5 pe-none"></i>
                                </button>
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn btn-blue btn-lg w-100" onClick={submitLoginForm} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="LOGIN" />
                                </button>
                            </div>
                            <div className="d-flex justify-content-between pb-3">
                                <Link to="/forgot-password" className="anchor-blueorange fw-medium" title='Forgot Password?'>Forgot Password?</Link>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center bg-lightblue">
                        <img src="images/logo.png" className="w-100" alt="ausdreambuilt" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
