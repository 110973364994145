import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useParams } from "react-router-dom";
import { PROJECT_WEATHER_LOGS } from "../../../components/ApiRoutes";
import DataTables, { reloadUrlDataTable } from "../../../components/Datatables";

function WeatherLogs(props) {
    const projectId = useParams().project_id

    const [dt] = useState({
        dt_url: `${PROJECT_WEATHER_LOGS}/${projectId}`,
        dt_name: 'project_weather_log_list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false  },
            { data: "created_at", name: 'created_at', title: "Date" },
            { data: "weather_info", name: 'weather_info', title: "Weather INFORMATION", sortable: false, searchable: false },
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    let text = "";
                    let icon = <i className="bi bi-cloud weather-card-info-icon text-info"></i>;

                    if(records.weather_info && records.weather_info.current && records.weather_info.current.condition && records.weather_info.current.condition.text) {
                        text = records.weather_info.current.condition.text;

                        if(records.weather_info.current.condition.text.search(/Cloudy/i) >= 0) icon = <i className="bi bi-cloudy-fill weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/Rain/i) >= 0) icon = <i className="bi bi-cloud-rain weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/overcast/i) >= 0) icon = <i className="bi bi-cloud-haze2-fill weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/sunny/i) >= 0) icon = <i className="bi bi-cloud-sun weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/clear/i) >= 0) icon = <i className="bi bi-brightness-high weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/fog/i) >= 0) icon = <i className="bi bi-cloud-fog weather-card-info-icon text-info"></i>
                        else if(records.weather_info.current.condition.text.search(/mist/i) >= 0) icon = <i className="bi bi-cloud-haze weather-card-info-icon text-info"></i>
                        else icon = <i className="bi bi-cloud weather-card-info-icon text-info"></i>
                    }

                    if(records.weather_info && records.weather_info.forecast && records.weather_info.forecast.forecastday && records.weather_info.forecast.forecastday[0].day && records.weather_info.forecast.forecastday[0].day && records.weather_info.forecast.forecastday[0].day.maxtemp_c && records.weather_info.forecast.forecastday[0].day.mintemp_c) {
                        text = `${text} | ${records.weather_info.forecast.forecastday[0].day.maxtemp_c}/${records.weather_info.forecast.forecastday[0].day.mintemp_c}`
                    }
                    createRoot(td).render(<>
                        <div className="col-auto mb-2 weather-card-info">{icon} <div className="badge bg-info fs-16px rounded-25">{text} &#8451;</div></div>
                    </>)
                }
            },
        ]
    })

    useEffect(() => reloadUrlDataTable(dt, `${PROJECT_WEATHER_LOGS}/${projectId}`), [dt, projectId])

    return (
        <div className="table-responsive p-1">
            <DataTables dt_name={`project_weather_log_list`} />
        </div>
    )
}

export default WeatherLogs;