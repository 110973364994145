// Account Routes

export const ACCOUNT_LIST = "account/lists"
export const ACCOUNT_CREATE = "account/create"
export const ACCOUNT_EDIT = "account/edit"
export const ACCOUNT_UPDATE = "account/update"
export const ACCOUNT_CHANGE_STATUS = "account/change-status"
export const LOGOUT = "logout"
export const GET_ACTIVE_PROJECT_MANAGER = "get-active-project-manager"
export const GET_ACTIVE_CLIENT = "get-active-client"
export const GET_ACTIVE_PROJECT = "get-active-project"
export const GET_PROFILE = "get-profile"
export const CHANGE_PASSWORD = "change-password"
export const GENERATE_DRIVE_TOKEN = "account/generate-drive-token"
export const MULTIPLE_ACCOUNT_DELETE = "account/multiple-delete"

// Choice Routes

export const CHOICE_LIST = "choice/lists"
export const CHOICE_CREATE = "choice/create"
export const CHOICE_EDIT = "choice/edit"
export const CHOICE_UPDATE = "choice/update"
export const CHOICE_CHANGE_STATUS = "choice/change-status"
export const CHOICE_CHANGE_ORDER = "choice/change-order"

export const CHOICE_QUESTIONS_LIST = "choice/question-lists"
export const CHOICE_QUESTIONS_CREATE = "choice/question-create"
export const CHOICE_QUESTIONS_EDIT = "choice/question-edit"
export const CHOICE_QUESTIONS_UPDATE = "choice/question-update"
export const CHOICE_QUESTIONS_CHANGE_STATUS = "choice/question-change-status"
export const SET_QUESTIONS_MULTIPLE_OPTION = "choice/question-multiple-option-change"
export const CHOICE_QUESTIONS_CHANGE_ORDER = "choice/question-change-order"

export const CHOICE_ANSWER_LIST = "choice/answer-lists"
export const CHOICE_ANSWER_CREATE = "choice/answer-create"
export const CHOICE_ANSWER_EDIT = "choice/answer-edit"
export const CHOICE_ANSWER_UPDATE = "choice/answer-update"
export const CHOICE_ANSWER_CHANGE_STATUS = "choice/answer-change-status"
export const CHOICE_ANSWER_CHANGE_ORDER = "choice/answer-change-order"

// Design and Approval Routes

export const DESIGN_LIST = "design/lists"
export const DESIGN_CREATE = "design/create"
export const DESIGN_EDIT = "design/edit"
export const DESIGN_UPDATE = "design/update"
export const DESIGN_CHANGE_STATUS = "design/change-status"
export const DESIGN_CHANGE_ORDER = "design/change-order"

export const DESIGN_SUBCATEGORY_LIST = "design/sub-category-lists"
export const DESIGN_SUBCATEGORY_CREATE = "design/sub-category-create"
export const DESIGN_SUBCATEGORY_EDIT = "design/sub-category-edit"
export const DESIGN_SUBCATEGORY_UPDATE = "design/sub-category-update"
export const DESIGN_SUBCATEGORY_CHANGE_STATUS = "design/sub-category-change-status"
export const GET_ACTIVE_DESIGN_LIST = "get-active-design-list"

// Pre-construction and Approval Routes

export const PRE_CONSTRUCTION_LIST = "pre-construction/lists"
export const PRE_CONSTRUCTION_CREATE = "pre-construction/create"
export const PRE_CONSTRUCTION_EDIT = "pre-construction/edit"
export const PRE_CONSTRUCTION_UPDATE = "pre-construction/update"
export const PRE_CONSTRUCTION_CHANGE_STATUS = "pre-construction/change-status"
export const GET_ACTIVE_PRE_CONSTRUCTION_LIST = "get-active-pre-construction-list"
export const PRE_CONSTRUCTION_CHANGE_ORDER = "pre-construction/change-order"

// Cconstruction and Approval Routes

export const CONSTRUCTION_LIST = "construction/lists"
export const CONSTRUCTION_CREATE = "construction/create"
export const CONSTRUCTION_EDIT = "construction/edit"
export const CONSTRUCTION_UPDATE = "construction/update"
export const CONSTRUCTION_CHANGE_STATUS = "construction/change-status"
export const GET_ACTIVE_CONSTRUCTION_LIST = "get-active-construction-list"
export const CONSTRUCTION_CHANGE_ORDER = "construction/change-order"

// Post-construction and Approval Routes

export const POST_CONSTRUCTION_LIST = "post-construction/lists"
export const POST_CONSTRUCTION_CREATE = "post-construction/create"
export const POST_CONSTRUCTION_EDIT = "post-construction/edit"
export const POST_CONSTRUCTION_UPDATE = "post-construction/update"
export const POST_CONSTRUCTION_CHANGE_STATUS = "post-construction/change-status"
export const GET_ACTIVE_POST_CONSTRUCTION_LIST = "get-active-post-construction-list"
export const POST_CONSTRUCTION_CHANGE_ORDER = "post-construction/change-order"

// Dashboard Routes

export const DASHBOARD_STATS = "statistics"

// Project Routes

export const PROJECT_LIST = "project/lists"
export const PROJECT_CREATE = "project/create"
export const PROJECT_EDIT = "project/edit"
export const PROJECT_UPDATE = "project/update"
export const PROJECT_CHANGE_STATUS = "project/change-status"
export const PROJECT_CHOICES = "project/choices"
export const PROJECT_CHOICES_UPDATE = "project/choices-update"
export const PROJECT_CHOICES_LIST = "project/choices-list"
export const GET_PROJECT_CHOICES_LIST = "project/choices/list"
export const DELETE_PROJECT_CHOICES_QUESTION = "project/choices/question/delete"
export const PROJECT_TASK_LIST = "project/task"
export const PROJECT_TASK_CREATE = "project/task-create"
export const PROJECT_TASK_UPDATE = "project/task-update"
export const PROJECT_TASK_DETAILS_CREATE = "project/create-task-details"
export const PROJECT_TASK_DETAILS_UPDATE = "project/update-task-details"
export const PROJECT_TASK_DETAILS_DELETE = "project/delete-task-details"
export const GET_PROJECT_TASK_DETAILS = "project/get-task-details"
export const GET_PROJECT_TASK = "project/task"
export const PROJECT_TASK_CHANGE_STATUS = "project/task-change-status"
export const GET_PROJECT_BY_STATUS = "get-project-by-status"
export const PROJECT_LOGS = "project/project-log"
export const PROJECT_REPORT = "project/project-report"
export const PROJECT_WEATHER_LOGS = "project/project-weather-logs"
export const PROJECT_TASK_BY_CATEGORY= "project/project-task-by-category"

// Inventory Routes

export const INVENTORY_LIST = "inventory/lists"
export const INVENTORY_CREATE = "inventory/create"
export const INVENTORY_EDIT = "inventory/edit"
export const INVENTORY_UPDATE = "inventory/update"
export const INVENTORY_CHANGE_STATUS = "inventory/change-status"
export const INVENTORY_SPEND_LIST = "inventory/spend-list"
export const INVENTORY_SPEND_CREATE = "inventory/spend-save"

//Permission
export const PERMISSION = "account/permission"

//LOG  URL
export const LOG_URL = "account/log"

//WEATEHER
export const WEATHER_INFO = 'get-weather-report' 

//SETTINGS
export const GET_SETTINGS = 'get-settings' 
export const UPDATE_SETTINGS = 'update-settings' 